import React, {Component} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Link, Route, Switch} from 'react-router-dom';

import Home from './components/home';
import Edit from './components/edit-item';
import Index from './components/list-items';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <Link to={'/home'} className="navbar-brand">WhatsMat</Link>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <a href="http://rest.whatsmat.de" target="_blank" rel="noopener noreferrer"
                     className="nav-link">API</a>
                </li>
                <li className="nav-item">
                  <Link to={'/index'} className="nav-link">Profile</Link>
                </li>
              </ul>
            </div>
          </nav>
          <Switch>
            <Route path='/home' component={Home}/>
            <Route path='/edit/:id' component={Edit}/>
            <Route path='/index' component={Index}/>
          </Switch>
        </div>
      </Router>
    );
  }
}

/*

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App">
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="#home">WhatsMat</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="http://rest.whatsmat.de">API</Nav.Link>
              <Nav.Link href="http://rest.whatsmat.de">Profile</Nav.Link>
              <Link to={'/index'} className="nav-link">Index</Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Container className="App">

        </Container>
      </div>
    );
  }
}*/

export default App;
