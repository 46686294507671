import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class TableRow extends Component {
  render() {
    return (
      <tr>
        <td>
          {this.props.obj.name}
        </td>
        <td>
          {this.props.obj.birthday}
        </td>
        <td>
          {this.props.obj.gender}
        </td>
        <td>
          {this.props.obj.last_country}
        </td>
        <td>
          <Link to={"/edit/" + this.props.obj.id} className="btn btn-primary">Edit</Link>
        </td>
      </tr>
    );
  }
}

export default TableRow;