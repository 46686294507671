import React, {Component} from 'react';
import ItemService from "../shared/item-service";
import TableRow from '../shared/table-row';

class ListItems extends Component {
  constructor(props) {
    super(props);
    this.state = {items: []}
    this.itemService = new ItemService();
  }

  componentDidMount() {
    this.itemService.getItems()
      .then(items => {
        this.setState({items: items});
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  tabRow() {
    return this.state.items.map(function (object, i) {
      return <TableRow obj={object} key={i}/>;
    });
  }

  render() {
    return (
      <div>
        <h3 align="center">Athletes</h3>
        <table className="table table-striped" style={{marginTop: 20}}>
          <thead>
          <tr>
            <th>Name</th>
            <th>Birthday</th>
            <th>Gender</th>
            <th>Last country</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          {this.tabRow()}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ListItems;
