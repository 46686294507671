import React, {Component} from 'react';
import Validator from '../shared/validator';
import ItemService from "../shared/item-service";

class EditItem extends Component {
  constructor(props) {
    super(props);
    this.validator = new Validator();
    this.itemService = new ItemService();
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      name: '',
      birthday: '',
      gender: '',
      last_country: ''
    }
  }

  componentDidMount() {
    this.itemService.getItem(this.props.match.params.id)
      .then(item => {
        this.setState({
          name: item.name,
          birthday: item.birthday,
          gender: item.gender,
          last_country: item.last_country
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  onCancel() {
    this.props.onCancel();
  }

  onSubmit() {
    if (this.validator.validateInputs(this.state)) {
      this.props.onSubmit(this.state);
    }
  }

  render() {
    return (
      <div style={{marginTop: 10}}>
        <h3 align="center">Update Profile</h3>
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <label>Name: </label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={this.state.name}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Birthday: </label>
            <input type="text"
                   className="form-control"
                   name="birthday"
                   value={this.state.birthday}
                   onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Gender: </label>
            <input type="text"
                   className="form-control"
                   name="gender"
                   value={this.state.gender}
                   onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Last country: </label>
            <input type="text"
                   className="form-control"
                   name="last_country"
                   value={this.state.last_country}
                   onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <input type="submit"
                   onClick={() => this.onSubmit()}
                   value="Update"
                   className="btn btn-primary"/>
            <input type="button"
                   onClick={() => this.onCancel()}
                   value="Cancel"
                   className="btn btn-primary"/>
          </div>
        </form>
      </div>
    );
  }
}

export default EditItem;